<template>
  <div v-loading.fullscreen.lock="fullscreenLoading" class="detail-box">
    <!-- 基础合同信息-->
    <template>
      <h3 class="title">
        基础合同信息
      </h3>
      <div class="basic-information">
        <div class="animate-block">
          <el-table :border="true" class="br-table" empty-text="暂无数据" :data="detailList.billContractList1">
            <el-table-column label="合同编号" prop="contractNo" />
            <el-table-column label="合同签订日期" prop="contractDate">
              <template slot-scope="scope">
                {{ scope.row.contractDate|date }}
              </template>
            </el-table-column>
            <el-table-column label="合同金额" prop="contractAmt">
              <template slot-scope="scope">
                {{ scope.row.contractAmt|formatMoney }}
              </template>
            </el-table-column>
          </el-table>
          <div
            v-if="detailList.billContractList.length>1"
            class="animate-block-control"
            @click="toggleBox('billContractList')"
          >
            <template v-if="!billContractListShow">
              <i class="br-iconfont iconfont icon-arrow-prev" /><span>显示</span>
            </template>
            <template v-else>
              <i class="br-iconfont iconfont icon-arrow-up" /><span>隐藏</span>
            </template>
          </div>
        </div>
        <div class="animate-block">
          <el-table :border="true" class="br-table" empty-text="暂无数据" :data="detailList.invoiceList1">
            <el-table-column label="发票代码" prop="invoiceCode" />
            <el-table-column label="发票号码" prop="invoiceNo" />
            <el-table-column label="发票金额" prop="invoiceAmt">
              <template slot-scope="scope">
                {{ scope.row.invoiceAmt|formatMoney }}
              </template>
            </el-table-column>
          </el-table>
          <div v-if="detailList.invoiceList.length>1" class="animate-block-control" @click="toggleBox('invoiceList')">
            <template v-if="!invoiceListShow">
              <i class="br-iconfont iconfont icon-arrow-prev" /><span>显示</span>
            </template>
            <template v-else>
              <i class="br-iconfont iconfont icon-arrow-up" /><span>隐藏</span>
            </template>
          </div>
        </div>
      </div>
    </template>
    <!-- 原始债权信息-->
    <template>
      <h3 class="title">
        原始债权信息
      </h3>
      <ul class="basic-information clear">
        <li class="flex-box">
          <span class="li-label">信链开立方：</span><span class="flex1">{{ detailList.echainApplay.openCmpName }}</span>
        </li>
        <li class="flex-box">
          <span class="li-label">原始接收方：</span><span class="flex1">{{ detailList.echainApplay.recvCmpName }}</span>
        </li>
        <li class="flex-box">
          <span class="li-label">应收账款编号：</span><span class="flex1">{{ detailList.echainApplay.billNo }}</span>
        </li>
        <li class="flex-box">
          <span class="li-label">应收账款金额：</span><span
            class="flex1"
          >{{ detailList.echainApplay.billAmt|formatMoney }}</span>
        </li>
        <li class="flex-box">
          <span class="li-label">应收账款余额：</span><span
            class="flex1"
          >{{ detailList.echainApplay.payableAmt|formatMoney }}</span>
        </li>
        <li class="flex-box">
          <span class="li-label">应收账款起始日：</span><span class="flex1">{{ detailList.billStartDate|date }}</span>
        </li>
        <li class="flex-box">
          <span class="li-label">应收账款到期日：</span><span class="flex1">{{ detailList.echainApplay.acctDate|date }}</span>
        </li>
      </ul>
    </template>
    <!-- 债权转让信息-->
    <template>
      <h3 class="title">
        债权转让信息
      </h3>
      <div class="basic-information">
        <el-table :border="true" class="br-table" empty-text="暂无数据" :data="registerList['list']">
          <el-table-column label="信链流转编号" prop="echainFlowNo" />
          <el-table-column label="转让方" prop="payCmpName" />
          <el-table-column label="接收方" prop="recvCmpName" />
          <el-table-column label="信链转让金额" prop="echainAmt">
            <template slot-scope="scope">
              {{ scope.row.echainAmt|formatMoney }}
            </template>
          </el-table-column>
          <el-table-column label="信链转让日期" prop="echainRecvDate">
            <template slot-scope="scope">
              {{ scope.row.echainRecvDate|date }}
            </template>
          </el-table-column>
          <el-table-column label="信链登记状态" prop="registerId">
            <template slot-scope="scope">
              {{ scope.row.registerId?'已登':'未登' }}
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          v-if="registerList['total']>0"
          class="br-pagination"
          :current-page="registerList['pageNum']"
          :page-sizes="[10, 30, 50, 100]"
          :page-size="registerList['pageSize']"
          layout="total, sizes, prev, pager, next, jumper"
          :total="registerList['total']"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </template>
  </div>
</template>
<script>
import { selectInfoList, selectRegisterList } from '@/api/tradeFinancing.js'
export default {
  data() {
    return {
      fullscreenLoading: false,
      registerListParams: {},
      detailList: {
        invoiceList: [],
        billContractList1: [],
        billContractList: [],
        echainApplay: {}
      },
      registerList: {}
    }
  },
  computed: {
    FileUrl() {
      return this.$store.getters.getDictionaryItem('FILE_PREFIX_URL')
    }
  },
  created() {
    this.registerListParams = {
      id: this.$route.query.id,
      pageNum: 1,
      pageSize: 10
    }
    this.init()
  },
  methods: {
    init() {
      selectInfoList(this.$route.query.id, res => {
        if (res.code === 200) {
          this.detailList = res.data
          this.detailList['billContractList1'] = []
          this.detailList['invoiceList1'] = []
          this.detailList['billContractList1'][0] = res.data.billContractList[0]
          this.detailList['invoiceList1'][0] = res.data.invoiceList[0]
          this.getZhaiquanTable()
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      })
    },
    getZhaiquanTable() {
      this.fullscreenLoading = true
      selectRegisterList(this.registerListParams, res => {
        if (res.code === 200) {
          this.registerList = res.data
          this.fullscreenLoading = false
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      }, err => {
        this.fullscreenLoading = false
        this.$message({
          type: 'error',
          message: err
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .detail-box {
    background-color: #fff;
    padding: 14px 18px;

    .animate-block {
      margin-bottom: 24px;
      border: 1px solid #ebebeb;
      border-radius: 3px;
      transition: .2s;

      .animate-block-control {
        border-top: 1px solid #eaeefb;
        height: 44px;
        box-sizing: border-box;
        background-color: #fff;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        text-align: center;
        margin-top: -1px;
        color: #d3dce6;
        cursor: pointer;
        position: relative;

        i {
          font-size: 16px;
          line-height: 44px;
          transition: .3s;
          display: inline-block;
        }

        span {
          position: absolute;
          transform: translateX(-30px);
          font-size: 14px;
          line-height: 44px;
          transition: .3s;
          display: none;
        }

        &:hover {
          color: #409eff;
          background-color: #f9fafc;

          i {
            transform: translateX(-40px);
          }

          span {
            display: inline-block;
          }
        }
      }
    }

    .down-file {
      cursor: pointer;
      color: #409EFF;
    }

    .title {
      font-weight: 400;
      padding: 0 18px;
      margin: 0 -18px;
      font-size: 16px;
      line-height: 50px;
      height: 50px;
      border-bottom: 1px solid #EEF1F4;

      .tips-txt {
        cursor: pointer;
        font-weight: 400;
        font-size: 16px;
        width: max-content;
      }
    }

    .money-box {
      text-align: right;
      font-size: 14px;
      color: #606266;
      margin-top: 10px;
    }

    .red-color {
      color: #ff0000;
      font-size: 20px;
    }

    li {
      .flex1 {
        max-width: 80%;
        word-wrap: break-word;
      }
    }

    .basic-information {
      padding-top: 20px;

      li {
        float: left;
        width: 40%;
        font-size: 14px;
        color: #606266;
        padding-bottom: 10px;
        margin-right: 10px;
        line-height: 30px;

        .li-label {
          text-align: right;
          min-width: 126px;
          font-weight: 600;
        }

        .br-input {
          width: 65%;
        }
      }
    }

    .btn-box {
      text-align: center;
    }

    .file-box {
      padding-top: 20px;

      li {
        font-size: 14px;
        color: #606266;
        width: 100%;
        line-height: 30px;

        .li-label {
          text-align: right;
          min-width: 126px;
          width: 150px;
          font-weight: 600;
        }

        .file {
          span {
            color: #00aaff;

            &.txt {
              cursor: pointer;
            }
          }
        }
      }

    }
  }
</style>
